import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import "../../style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import works from "./projectdata.js";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>My recent projects.</p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {works.map((project, index) => {
            return (
              <Col md={4} className="project-card" key={index}>
                <ProjectCard
                  imgPath={project.screen}
                  isBlog={false}
                  title={project.title}
                  description={project.description}
                  link={project.link}
                  framework={project.framework}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
