import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import Resumecontent from "./ResumeContent";
import "../../style.css";
import "bootstrap/dist/css/bootstrap.min.css";

function Resume() {
  return (
    <Container fluid className="resume-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button
            variant="primary"
            href={
              "https://drive.google.com/file/d/1UdIYufghxtbTGWigAAExUDjlxrSvVMFv/view?usp=sharing"
            }
            target="_blank"
          >
            <i className="fas fa-download">&nbsp;</i>Download CV
          </Button>
        </Row>
        <Row className="resume">
          <Col md={6} className="resume-left">
            <h3 className="resume-title">Experience</h3>
            <Resumecontent
              title="Wutzu Technology Ltd (United Kingdom)"
              date="December 2019 - April 2021"
              content={[
                "Worked as a lead full stack developer developing ecommerce website using Python. NodeJS, React/React Native/Next and Firebase",
              ]}
            />
            <Resumecontent
              title="STLPRO.INC (United States)"
              date="January 2018 - June 2020"
              content={[
                "Achieved and upgraded drop shipping management website and automation bots and chrome extensions using HTML/CSS, Python/Django, NodeJS/Puppeteer and React.",
              ]}
            />
            <Resumecontent
              title="Beijing Huxinsheng Valve Co Ltd (China)"
              date="October 2014 - December 2017"
              content={[
                "Participated in web and mobile development in both back end and front end.",
              ]}
            />
            <h3 className="resume-title">Others</h3>
            <Resumecontent
              title="Upwork.com (United States)"
              date="January 2018 - May 2020"
              content={[
                "Worked as a freelancer to work with many clients on upwork platform. Engaged in machine learning, web and mobile development. Enhanced experience in React/React Native, JavaScript/TypeScript, Php/Laravel and other frameworks.",
              ]}
            />
          </Col>
          <Col md={6} className="resume-right">
            <h3 className="resume-title">Education</h3>
            <Resumecontent
              title="University of Science and Technology of China"
              date="2010 - 2014"
              content={["Bachelor's Degree: Computer Science"]}
            />
            <Resumecontent
              title="Cultural and Education Section British Embassy"
              date="2009 - 2010"
              content={["English: Advanced Level"]}
            />
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button
            variant="primary"
            href={
              "https://drive.google.com/file/d/1UdIYufghxtbTGWigAAExUDjlxrSvVMFv/view?usp=sharing"
            }
            target="_blank"
          >
            <i className="fas fa-download">&nbsp;</i>Download CV
          </Button>
        </Row>
      </Container>
    </Container>
  );
}

export default Resume;
