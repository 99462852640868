import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/id.png";
import "../../style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Tilt from "react-parallax-tilt";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              Hello, I am a senior software/ full stack developer with almost 10
              years of experience. With Bachelors Degree in Computer Science and
              Software Engineering, I have worked on many projects and different
              aspects of web and mobile development. During my working days, I
              have enriched and enhanced my knowledge and experience. For the
              past 5 years, I have been engaged in remote work to collaborate
              with different developers from different countries. That made me
              have good ethics and experience in remote work and team work with
              different people.
              <br />I have always been trying to achieve the best results in my
              work with the fast and high quality service. And I have keen
              interest in machine learning and data science. Being proactive and
              having growth mindset, I strive to better myself and enrich my
              experience. Thank you for your attention.
              <i>
                <b className="purple"> Machine Learning</b>
              </i>
              &nbsp; and
              <i>
                <b className="purple"> Data Science.</b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="mailto:xiaoping.jin.210@gmail.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <i class="fas fa-envelope-square"></i>
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://github.com/fortdev210"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <i className="fab fa-github"></i>
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://join.skype.com/invite/gzmCUFdkFwx5"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <i className="fab fa-skype"></i>
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/kot-jin/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://discord.gg/Qyxz864U"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <i className="fab fa-discord"></i>
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
