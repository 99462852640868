import WUTZU_LANDING from "../../Assets/Projects/wutzu.png";
import WUTZU_APP from "../../Assets/Projects/wutzu_app.png";
import WUTZU_STORES from "../../Assets/Projects/wutzu-app.png";
import WORK_WELL from "../../Assets/Projects/workwell.png";
import EMPLOYEEZNOW from "../../Assets/Projects/employ.svg";
import ISINET from "../../Assets/Projects/isinet.svg";
// import BLUECLERK from "../../Assets/Projects/blueclerk.png";
import FAHURA from "../../Assets/Projects/furaha.png";
import CASINO from "../../Assets/Projects/casino.png";
import NLP from "../../Assets/Projects/nlp.jpg";

const works = [
  {
    screen: WUTZU_LANDING,
    title: "Wutzu Landing Site",
    framework: {
      Frontend: "JavaScript/NextJS",
      "UI framework": "Ant Design",
      Backend: "NodeJS, Firebase",
      Others: "Google Analytics",
    },
    description: "Wutzu Landing Page, Entry Point to great service in London",
    link: "https://wutzu.com",
  },
  {
    screen: WUTZU_APP,
    title: "Wutzu Web App",
    framework: {
      Frontend: "React/Redux, JavaScript, HTML, CSS",
      "UI framework": "Ant Design",
      Backend: "NodeJS, Firebase",
      DB: "Firebase Realtime DB and google cloud storage",
      Others: "Google Analytics, TawkTo, Twillio, Stripe, Firebase",
    },
    description:
      "Find suitable stores and products using post code. Instant delivery assurance.",
    link: "https://app.wutzu.com/",
  },
  {
    screen: WUTZU_STORES,
    title: "Wutzu Stores App",
    framework: {
      Frontend: "React/Redux, JavaScript, HTML, CSS",
      "UI framework": "Ant Design",
      Backend: "NodeJS, Firebase, Python",
      DB: "Firebase Realtime DB and google cloud storage",
    },
    description: "Wutzu products management web application",
    link: "https://stores.wutzu.com/",
  },
  {
    screen: WORK_WELL,
    title: "We Work Well Together",
    framework: {
      Frontend: "React/Redux, JavaScript, HTML, CSS, Styled Component",
      "UI framework": "Material UI",
      Backend: "Python, DjangoRestFramework",
      DB: "PostgreSQL",
      Others: "Psycology,",
    },
    link: "https://www.workwelltogether.co/",
    description:
      "Find suitable match for a new employee using psychology and brand new algorithm.",
  },
  {
    screen: EMPLOYEEZNOW,
    title: "Remote Employee Website",
    framework: {
      Frontend: "React/Redux, JavaScript, HTML, CSS",
      "UI framework": "Material UI",
      Backend: "NodeJS, Express",
      DB: "MongoDB",
      Others: "Twillio, Stripe, SendGrid ",
    },
    description:
      "Look for employees meeting your requirements through contest and profile.",
    link: "http://employeeznow.com/",
  },
  {
    screen: ISINET,
    title: "ISINET Admin Website",
    framework: {
      Frontend: "HTML, CSS, Jquery",
      "UI framework": "Bootstrap",
      Backend: "Php, Laravel",
      DB: "MySQL",
    },
    link: "https://app.proages.net/aevum/usuarios/login.html",
    description:
      "ISINET is Mexico based stock company and financial and sales analysis platform.",
  },
  //   {
  //     screen: BLUECLERK,
  //     title: "Blue Clerk IoT SAAS Platform",
  //     framework: {
  //       Frontend: "React/Redux, Typescript, HTML, CSS",
  //       "UI framework": "Material UI",
  //       Backend: "NodeJS, Express",
  //       DB: "MongoDB",
  //       Others: "Internet of Things",
  //     },
  //     description: "Internet Of Things Data Processing and payment management",
  //     link: "http://blueclerk.com/",
  //   },
  {
    screen: FAHURA,
    title: "Furaha Service Mobile App",
    framework: {
      Frontend: "React Native",
      Backend: "Php, Laravel",
      DB: "MySQL",
    },
    description:
      "Fahura is mobile and desktop web application for makeup and beauty.",
    link: "https://furaha.co.uk/",
  },
  {
    screen: CASINO,
    title: "Casino Betting Site",
    framework: {
      Frontend: "React/Redux, Typescript, HTML, CSS",
      "UI framework": "Tailwind CSS",
      Backend: "NodeJS, LOOP",
      DB: "MySQL",
      Others: "TawkTo, Bitcoin",
    },
    description: "Blockchain integrated casino service provider",
    link: "https://caltechcasino.com/",
  },
  {
    screen: NLP,
    title: "Natural Language Processing Application",
    description:
      "A natural language processing web application for sentiment analysis, topic extractor and article generator.",
    framework: {
      Frontend: "React/Redux, JavaScript, HTML, CSS",
      "UI framework": "Material UI",
      Backend: "Python, Django",
      DB: "MySQL",
      Others: "Natural Language Processing, Data Science",
    },
    link: "https://emnlp.herokuapp.com/",
  },
];

export default works;
