import React from "react";
import Card from "react-bootstrap/Card";
import "../../style.css";
import "bootstrap/dist/css/bootstrap.min.css";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>My best skills are</p>
          <ul>
            <li className="about-activity">
              <i className="far fa-hand-point-right"></i> JavaScript/TypeScript,
              React/React Native/Next, Vue/Quasar
            </li>
            <li className="about-activity">
              <i className="far fa-hand-point-right"></i> NodeJS/Express/NestJS,
              Python/Django, Php/Laravel
            </li>
            <li className="about-activity">
              <i className="far fa-hand-point-right"></i> Web Scraping and
              automation
            </li>
            <li className="about-activity">
              <i className="far fa-hand-point-right"></i> Machine Learning and
              Data Science
            </li>
          </ul>

          <p style={{ marginBlockEnd: 0, color: "rgb(155 126 172)" }}>
            "Sharpen the saw."{" "}
          </p>
          <footer className="blockquote-footer">Ping Jin</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
